import React from 'react'

const FallingGifs = () => {
  return (
    <>
      <div class="snowflakes">
        <div class="snowflake">
          <img src="./fire.gif" width="50"/>
        </div>
        <div class="snowflake">
          <img src="./fire.gif" width="50"/>
        </div>
        <div class="snowflake">
          <img src="./fire.gif" width="50"/>
        </div>
        <div class="snowflake">
          <img src="./fire.gif" width="50"/>
        </div>
        <div class="snowflake">
          <img src="./fire.gif" width="50"/>
        </div>
        <div class="snowflake">
          <img src="./fire.gif" width="50"/>
        </div>
        <div class="snowflake">
          <img src="./fire.gif" width="50"/>
        </div>
        <div class="snowflake">
          <img src="./fire.gif" width="50"/>
        </div>
        <div class="snowflake">
          <img src="./fire.gif" width="50"/>
        </div>
        <div class="snowflake">
          <img src="./fire.gif" width="50"/>
        </div>
      </div>
      <div class="snowflakesmobile">
        <div class="snowflake2">
          <img src="./fire.gif" width="30"/>
        </div>
        <div class="snowflake2">
          <img src="./fire.gif" width="30"/>
        </div>
        <div class="snowflake2">
          <img src="./fire.gif" width="30"/>
        </div>
        <div class="snowflake2">
          <img src="./fire.gif" width="30"/>
        </div>
        <div class="snowflake2">
          <img src="./fire.gif" width="30"/>
        </div>
        <div class="snowflake2">
          <img src="./fire.gif" width="30"/>
        </div>
        <div class="snowflake2">
          <img src="./fire.gif" width="30"/>
        </div>
        <div class="snowflake2">
          <img src="./fire.gif" width="30"/>
        </div>
        <div class="snowflake2">
          <img src="./fire.gif" width="30"/>
        </div>
        <div class="snowflake2">
          <img src="./fire.gif" width="30"/>
        </div>
        <div class="snowflake2">
          <img src="./fire.gif" width="30"/>
        </div>
        <div class="snowflake2">
          <img src="./fire.gif" width="30"/>
        </div>
        <div class="snowflake2">
          <img src="./fire.gif" width="30"/>
        </div>
        <div class="snowflake2">
          <img src="./fire.gif" width="30"/>
        </div>
      </div>
      <div class="snowflakesmobile2">
        <div class="snowflake2">
          <img src="./fire.gif" width="30"/>
        </div>
        <div class="snowflake2">
          <img src="./fire.gif" width="30"/>
        </div>
        <div class="snowflake2">
          <img src="./fire.gif" width="30"/>
        </div>
        <div class="snowflake2">
          <img src="./fire.gif" width="30"/>
        </div>
        <div class="snowflake2">
          <img src="./fire.gif" width="30"/>
        </div>
        <div class="snowflake2">
          <img src="./fire.gif" width="30"/>
        </div>
        <div class="snowflake2">
          <img src="./fire.gif" width="30"/>
        </div>
        <div class="snowflake2">
          <img src="./fire.gif" width="30"/>
        </div>
        <div class="snowflake2">
          <img src="./fire.gif" width="30"/>
        </div>
        <div class="snowflake2">
          <img src="./fire.gif" width="30"/>
        </div>
        <div class="snowflake2">
          <img src="./fire.gif" width="30"/>
        </div>
        <div class="snowflake2">
          <img src="./fire.gif" width="30"/>
        </div>
        <div class="snowflake2">
          <img src="./fire.gif" width="30"/>
        </div>
        <div class="snowflake2">
          <img src="./fire.gif" width="30"/>
        </div>
      </div>
    </>
  )
}

export default FallingGifs
