import React from 'react'
import Countdown from './Countdown'

function BattleText() {
    return (
        <div className='battletext-wrap'>
            <a
                href="https://mint.tribesofogun.io/"
                target="_blank"
                className='no-under'
            >
                <button
                    className="nav-button mint-now"
                >
                    MINT NOW
                </button>
            </a>
            <div className="no-margin center-box">
                <p className='battle-text'>
                    The first tribe battle has started!<br/> The task at hand : Collect more fire elements than the opposing tribes to win this weeks battle <br/> <a href='https://mint.tribesofogun.io/' target="_blank">Mint your FIRE NFT</a> to help your tribe fight for control of YOBURALAND
                </p>
                <p className="battle-text">Battle Ends In:</p>
                <br/>
                <Countdown/>
            </div>

        </div>
    )
}

export default BattleText