import React from 'react'

function TribeLeadersNavigation(props) {
    return (
        <div className="navigation-menu">
            <hr/>
            <button
                className="nav-button"
                onClick={() => props.setTribeLeadersMenuItem("Black Panther")}
            >
                Black Panther
            </button>
            <button
                className="nav-button"
                onClick={() => props.setTribeLeadersMenuItem("Cockatoo")}
            >
                Cockatoo
            </button>
            <button
                className="nav-button"
                onClick={() => props.setTribeLeadersMenuItem("Gorilla")}
            >
                Gorilla
            </button>
            <button
                className="nav-button"
                onClick={() => props.setTribeLeadersMenuItem("Mandrill")}
            >
                Mandrill
            </button>
            <button
                className="nav-button"
                onClick={() => props.setTribeLeadersMenuItem("Rhino")}
            >
                Rhino
            </button>
        </div>
    )
}

export default TribeLeadersNavigation