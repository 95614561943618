import React, {useState, useEffect} from 'react';
import { Pie } from 'react-chartjs-2';
import { collection, getDocs } from 'firebase/firestore';
import moment from 'moment'

function PieChart(props) {

  const buildPieGraphData = (dataArray) => {
    let dataToAdd = [];
    let backgroundColorToAdd = [];
    let borderColorToAdd = [];
    for(let i = 0 ; i < dataArray.length ; i++) {
      dataToAdd[i] = dataArray[i].value;
      backgroundColorToAdd[i] = dataArray[i].backgroundColor;
      borderColorToAdd[i] = dataArray[i].borderColor;
    }
    const data = {
      datasets: [
        {
          label: 'Tribes of Ogun',
          data: dataToAdd,
          backgroundColor: backgroundColorToAdd,
          borderColor: borderColorToAdd,
          borderWidth: 1,
        },
      ],
    };
    return data
  }

  const [pieGraphData, setPieGraphData] = useState(buildPieGraphData([1,1,1,1,1]));
  const [timeFromLastUpdated, setTimeFromLastUpdated] = useState()
  const [timeUntilNextUpdate, setTimeUntilNextUpdate] = useState()
  const [orderedAnimalLeaders, setOrderedAnimalLeaders] = useState([])

  const getTimeFromLastUpdated = (firestoreTimestamp) => {
    const hours = firestoreTimestamp.toDate().getHours()
    const minutes = firestoreTimestamp.toDate().getMinutes()
    const seconds = firestoreTimestamp.toDate().getSeconds()
    const formattedDate = hours + ':' + minutes + ':' + seconds
    const timestamp = moment(formattedDate, 'HH:mm:ss')
    const diff = timestamp.diff(moment(), 'minutes')
    const positiveDiff = Math.abs(diff)
    setTimeFromLastUpdated(positiveDiff)
    getTimeUntilNextUpdate(positiveDiff)
  }

  const getTimeUntilNextUpdate = (lastUpdated) => {
    const functionExectutionPeriod = 45
    let timeUntilNextUpdate = functionExectutionPeriod - lastUpdated
    if(timeUntilNextUpdate < 0) {
      timeUntilNextUpdate = 0
    }
    setTimeUntilNextUpdate(timeUntilNextUpdate)
  }

  const orderLeaders = (animalArrayOfObjects) => {
    let orderedItems = animalArrayOfObjects.sort(function(a, b) {
      return b.value - a.value;
    });
    return orderedItems;
  }

  const getBattlesRecords = async () => {
    const querySnapshot = await getDocs(collection(props.db, 'battles'));
    querySnapshot.forEach((doc) => {
      getTimeFromLastUpdated(doc.data().lastUpdated);
      let animalArrayOfObjects = [
        {
          name: "COCKATOO",
          value: doc.data().COCKATOO,
          label: "Cockatoo",
          backgroundColor: 'rgb(215 133 71)',
          borderColor: 'rgb(215 133 71)'
        },
        {
          name: "BLACKJAG",
          value: doc.data().BLACKPANTHER,
          label: "Black Panther",
          backgroundColor: 'rgb(232 178 88)',
          borderColor: 'rgb(232 178 88)'
        },
        {
          name: "MANDRILL",
          value: doc.data().MANDRILL,
          label: "Mandrill",
          backgroundColor: 'rgb(131 53 46)',
          borderColor: 'rgb(131 53 46)'
        },
        {
          name: "RHINO",
          value: doc.data().RHINO,
          label: "Rhino",
          backgroundColor: 'rgb(119 135 135)',
          borderColor: 'rgb(119 135 135)'
        },
        {
          name: "GORILLA",
          value: doc.data().GORILLA,
          label: "Gorilla",
          backgroundColor: 'rgb(42 27 14)',
          borderColor: 'rgb(42 27 14)'
        },
      ];
      let orderLeadersReturnValue = orderLeaders(animalArrayOfObjects)
      setOrderedAnimalLeaders(orderLeadersReturnValue);
      setPieGraphData(buildPieGraphData(orderLeadersReturnValue));
    });
  };
  
  useEffect(() => {
    getBattlesRecords();
  }, []);
  
  return (
    <div className='chart-wrap'>

      <Pie data={pieGraphData} height={100} width={100}/>

      <div className='legend'>
        {
          orderedAnimalLeaders.map((value, index) => (
            <div className='legend-item' key={index}>
              <span className='legend-color' style={{backgroundColor: value.backgroundColor}} />
              <span className='legend-label'>
                {index + 1}. {value.label}: {value.value}
              </span>
            </div>
          ))
        }
      </div>

      <p className="time-update-label">
        Last updated {timeFromLastUpdated} minutes ago
      </p>
      {
        timeUntilNextUpdate === 0 ?
        <p className="time-update-label">
          Updating momentarily
        </p>
        :
        <p className="time-update-label">
          Next update in {timeUntilNextUpdate} minutes
        </p>
      }
    </div>
  )
};

export default PieChart;