import './App.css';
import PieChart from './Components/PieChart';
import AboveChart from './Components/AboveChart';
import {useState} from 'react'
import NavigationMenu from './Components/NavigationMenu'
import TribeLeaders from './Components/TribeLeaders'
import FallingGifs from './Components/FallingGifs';
import BattleText from './Components/BattleText';

function App(props) {

  const [currentMenuItem, setCurrentMenuItem] = useState("Leaderboard")

  return (
    <div className='page-wrap'>
      <FallingGifs />
      <video autoPlay loop muted className='bg-vid' >
        <source src="https://uploads-ssl.webflow.com/614c8eb354dc037fcbe67dc9/614e52e14bd0c8c30e115810_Untitled (2)-transcode.mp4" data-wf-ignore="true"></source>
      </video>
      <div className='content-wrap'>
        <AboveChart />
        <BattleText/>
        <div className='center-box'>
          <h1 className='chart-title'>Tribe Leaderboards</h1>
          <NavigationMenu setCurrentMenuItem={setCurrentMenuItem} />
          {
            currentMenuItem === "Leaderboard" &&
            <PieChart db={props.db} />
          }
          {
            currentMenuItem === "TribeLeaders" &&
            <TribeLeaders db={props.db} />
          }
        </div>        
      </div>
    </div>
  );
}

export default App;
