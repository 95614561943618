import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyDAWa_z2hjeY-ZK8W0ikIZ8g6QpCdGBuwA',
  authDomain: 'tarzan-game.firebaseapp.com',
  databaseURL: 'https://tarzan-game-default-rtdb.firebaseio.com',
  projectId: 'tarzan-game',
  storageBucket: 'tarzan-game.appspot.com',
  messagingSenderId: '708026271958',
  appId: '1:708026271958:web:4358546f24883214fa6177'
};

initializeApp(firebaseConfig);
const db = getFirestore();

ReactDOM.render(
  <React.StrictMode>
     <App db={db} />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
