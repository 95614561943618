import React from 'react'

const AboveChart = () => {
  return (
    <div className='above-chart'>
      <img src="./tribes_logo.png" className='tribes-logo'/>
      <h1 className='chart-title'>BATTLE Week 1: FIRE</h1>
    </div>
  )
}

export default AboveChart
