import React, {useState, useEffect} from 'react'
import { collection, getDocs } from 'firebase/firestore';

function TribeLeadersAddresses(props) {

    const [blackPantherLeaders, setBlackPantherLeaders] = useState([])
    const [cockatooLeaders, setCockatooLeaders] = useState([])
    const [gorillaLeaders, setGorillaLeaders] = useState([])
    const [mandrillLeaders, setMandrillLeaders] = useState([])
    const [rhinoLeaders, setRhinoLeaders] = useState([])

    const getTopLeaders = async () => {
        const querySnapshot = await getDocs(collection(props.db, 'battles', 'week1', 'leaderboards'));
        
        querySnapshot.forEach((doc) => {
            console.log(doc.data())
            if(doc.id === "blackpather") {
                setBlackPantherLeaders(doc.data())
            } else if(doc.id === "cockatoo") {
                setCockatooLeaders(doc.data())
            } else if(doc.id === "gorilla") {
                setGorillaLeaders(doc.data())
            } else if(doc.id === "mandrill") {
                setMandrillLeaders(doc.data())
            } else if(doc.id === "rhino") {
                setRhinoLeaders(doc.data())
            }
        })
    }

    useEffect(() => {
        getTopLeaders();
    }, [])


    const renderLeaders = (leaders) => {
        let row = [];
        for (const index in leaders) {
            if (index > 9 ) {
                return row;
            }

            if (leaders[index][1] > 0) {
                row.push(
                    <>
                        <a href={'https://opensea.io/' + leaders[index][0]} target='_blank' rel='norefferer'>{leaders[index][0]}</a>
                        <p>Points Accumulated: {leaders[index][1]}</p>
                    </>
                )                
            }
        }
        return row;
    }

    return (
        <div className="tribe-leaders">
            <h2>{props.tribeLeadersMenuItem}</h2>
            {
                props.tribeLeadersMenuItem === "Black Panther" &&
                blackPantherLeaders.length !== 0 &&
                <div className="section-text">
                    {/* <a href={'https://opensea.io/' + blackPantherLeaders[0][0]} target='_blank' rel='norefferer'>{blackPantherLeaders[0][0]}</a>
                    <p>Points Accumulated: {blackPantherLeaders[0][1]}</p>
                    <a href={'https://opensea.io/' + blackPantherLeaders[1][0]} target='_blank' rel='norefferer'>{blackPantherLeaders[1][0]}</a>
                    <p>Points Accumulated: {blackPantherLeaders[1][1]}</p> */}

                    {renderLeaders(blackPantherLeaders)}
                </div>
            }
            {
                props.tribeLeadersMenuItem === "Cockatoo" &&
                cockatooLeaders.length !== 0 &&
                <div className="section-text">
                    {/* <a href={'https://opensea.io/' + cockatooLeaders[0][0]} target='_blank' rel='norefferer'>{cockatooLeaders[0][0]}</a>
                    <p>Points Accumulated: {cockatooLeaders[0][1]}</p>
                    <a href={'https://opensea.io/' + cockatooLeaders[1][0]} target='_blank' rel='norefferer'>{cockatooLeaders[1][0]}</a>
                    <p>Points Accumulated: {cockatooLeaders[1][1]}</p> */}
                    {renderLeaders(cockatooLeaders)}
                </div>
            }
            {
                props.tribeLeadersMenuItem === "Gorilla" &&
                gorillaLeaders.length !== 0 &&
                <div className="section-text">
                    {/* <a href={'https://opensea.io/' + gorillaLeaders[0][0]} target='_blank' rel='norefferer'>{gorillaLeaders[0][0]}</a>
                    <p>Points Accumulated: {gorillaLeaders[0][1]}</p>
                    <a href={'https://opensea.io/' + gorillaLeaders[1][0]} target='_blank' rel='norefferer'>{gorillaLeaders[1][0]}</a>
                    <p>Points Accumulated: {gorillaLeaders[1][1]}</p> */}
                    {renderLeaders(gorillaLeaders)}
                </div>
            }
            {
                props.tribeLeadersMenuItem === "Mandrill" &&
                mandrillLeaders.length !== 0 &&
                <div className="section-text">
                    {/* <a href={'https://opensea.io/' + mandrillLeaders[0][0]} target='_blank' rel='norefferer'>{mandrillLeaders[0][0]}</a>
                    <p>Points Accumulated: {mandrillLeaders[0][1]}</p>
                    <a href={'https://opensea.io/' + mandrillLeaders[1][0]} target='_blank' rel='norefferer'>{mandrillLeaders[1][0]}</a>
                    <p>Points Accumulated: {mandrillLeaders[1][1]}</p> */}
                    {renderLeaders(mandrillLeaders)}
                </div>
            }
            {
                props.tribeLeadersMenuItem === "Rhino" &&
                rhinoLeaders.length !== 0 &&
                <div className="section-text">
                    {/* <a href={'https://opensea.io/' + rhinoLeaders[0][0]} target='_blank' rel='norefferer'>{rhinoLeaders[0][0]}</a>
                    <p>Points Accumulated: {rhinoLeaders[0][1]}</p>
                    <a href={'https://opensea.io/' + rhinoLeaders[1][0]} target='_blank' rel='norefferer'>{rhinoLeaders[1][0]}</a>
                    <p>Points Accumulated: {rhinoLeaders[1][1]}</p> */}
                    {renderLeaders(rhinoLeaders)}
                </div>
            }
        </div>
    )
}

export default TribeLeadersAddresses