import React, {useState} from 'react'
import { useTimer } from 'react-timer-hook';

function Countdown() {

    const [expiryTimestamp, setExpiryTimestamp] = useState(1635037200000); 
	const { seconds, minutes, hours, days, isRunning } = useTimer({
		expiryTimestamp,
		onExpire: () => console.warn('onExpire called')
	});

    return (
        <div  className="battle-text">
            <span>
                <span className="timer-time">{days}</span>
                <span className='timer-word'>days </span>
            </span>
            <span>
                <span className='timer-time'>{hours}</span>
                <span className='timer-word'>hours </span>
            </span>
            <span>
                <span className='timer-time'>{minutes}</span>
                <span className='timer-word'>minutes </span>
            </span>
            <span>
                <span className='timer-time'>{seconds}</span>
                <span className='timer-word'>seconds</span>
            </span>
        </div>
    )
}

export default Countdown