import React, { useState } from 'react'

function NavigationMenu(props) {

    const [isLeaderboard, setIsLeaderboard] = useState(true);

    return (
        <div className="navigation-menu">
            {
                isLeaderboard ? 
                <button
                className="nav-button"
                onClick={() => {props.setCurrentMenuItem("TribeLeaders")
                                setIsLeaderboard(false)}}
                >
                    Tribe Leaders
                </button>:
                <button
                    className="nav-button"
                    onClick={() => {props.setCurrentMenuItem("Leaderboard")
                                    setIsLeaderboard(true)}}
                >
                    Leaderboard
                </button> 

            }        


            <a
                href="https://www.tribesofogun.io/about"
                target="_blank"
            >
                <button
                    className="nav-button"
                >
                    About
                </button>
            </a>
            <a
                href="https://www.tribesofogun.io/tribe-battle-prizes"
                target="_blank"
            >
                <button
                    className="nav-button"
                >
                    Prizes
                </button>
            </a>
            <a
                href="https://mint.tribesofogun.io/"
                target="_blank"
            >
                <button
                    className="nav-button"
                >
                    MINT
                </button>
            </a>
        </div>
    )
}

export default NavigationMenu