import React, {useState, useEffect} from 'react'
import TribeLeadersNavigation from './TribeLeadersNavigation'
import TribeLeadersAddresses from './TribeLeadersAddresses'

function TribeLeaders(props) {

    const [tribeLeadersMenuItem, setTribeLeadersMenuItem] = useState("Black Panther")

    return (
        <div>
            <TribeLeadersNavigation
                setTribeLeadersMenuItem={setTribeLeadersMenuItem}
            />
            <TribeLeadersAddresses
                tribeLeadersMenuItem={tribeLeadersMenuItem}
                db={props.db}
            />
        </div>
    )
}

export default TribeLeaders